
export default {
  data() {
    return {
      footer: null,
      lang: this.$router.currentRoute.params.lang,
    };
  },
  mounted() {
    this.getFooter();
  },
  watch: {
    $route() {
      this.lang = this.$router.currentRoute.params.lang;
      this.getFooter();
    },
  },
  methods: {
    async getFooter() {
      const { results } = await this.$prismic.client.query([
        this.$prismic.Predicates.at("document.type", "footer"),
        this.$prismic.Predicates.at("document.tags", [this.lang]),
      ]);
      this.footer = results[0].data;
      // console.log(this.footer);
    },
  },
};
